export const custom = {
  /**
  * Idiomas disponibles ['es','en','pt','cat']
  */
  availableLangs: ['es'],

  /**
   * Idioma por defecto: ['es']
   */
  defaultLang: 'es',

  /**
   * Versión de la API: v2|v3
   */
  apiVersion: 'v3',

  /**
   * API key de conexión a la api definida en cada entorno
   * (debe ser el mismo en todos los servicios)
   */
  apiKey: 'bdf6f629-bf40-4b60-8e8e-9c622759a168',

  /**
   * URL de la app
   *
   * subdominio.dominio
   */
  appUrlDomain: 'deutschetelekombusiness.alares',

  /**
  * Nombre de la app
  */
  appName: 'Plan Conciliación Deutsche Telekom Business',

  /**
  * Código de analytics 'G-XXXXXXXXXXX'
  * (Activado en pro, desactivado en pre y local)
  */
  analyticsId: 'G-7EDQXFSYY1',

  /** true o false. Define si es necesario hacer login para acceder a los contenidos */
  protected: true,

  /** Registro habilitado o deshabilitado */
  registration: true,

  /** true o false. Define si los formularios son para conciliación o no */
  conciliation: true,
  videoCallsUrl: 'https://videollamada.vivofacil.com/?kid=AljJRe39SLEsWhMFg2pqTrA',
  mailTransport: 'alares',
  brandName: 'Alares',
  appUrlTld: 'es',
  externalTopLink: {
    /** Enlace habilitado o deshabilitado */
    status: true,
    /** Titulo del enlace */
    title: 'Ayudas Sociales',
    /** URL del enlace */
    url: 'https://deutschetelekombusiness-ayudas-sociales.alares.es/check',
    /** Se añade la cadena `/xxxxx` a la url, donde xxxxx es el identificador del usuario en base64 */
    concatIdentifier: true
  }
};
